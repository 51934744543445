<script setup lang="ts">
import {computed, onMounted, onUnmounted, ref} from "vue";
import CodeBlock from "vue3-code-block";
import {useCodeTheme} from "~/utils/codeTheme";


const props = defineProps<{
  properties: any
}>()
const {codeTheme} = useCodeTheme()

const localStorageKey = 'propertyLanguage'
const eventKey = 'propertyLanguageChanged'
const propertiesType = ref('properties')

const dependencySystemEventListener = () => {
  propertiesType.value = localStorage.getItem(localStorageKey) as string
}

onMounted(() => {
  if (!localStorage.getItem(localStorageKey)) {
    localStorage.setItem(localStorageKey, 'properties')
  }
  propertiesType.value = localStorage.getItem(localStorageKey) as string

  watchEffect(() => {
    localStorage.setItem(localStorageKey, propertiesType.value)
    window.dispatchEvent(new Event(eventKey));
  })

  window.addEventListener(eventKey, dependencySystemEventListener)
})

onUnmounted(() => {
  window.removeEventListener(eventKey, dependencySystemEventListener)
});

const propertiesCode = computed(() => {
  // Convert nested object structure to properties file, using the keys as the property names
  const convertToProperties = (obj: any, prefix = '') => {
    let properties = ''
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key]
        if (value === null) continue;
        if (typeof value === 'object') {
          properties += convertToProperties(value, `${prefix}${key}.`)
        } else {
          properties += `${prefix}${key}=${value}\n`
        }
      }
    }
    return properties
  }
  return convertToProperties(props.properties)
})

const yamlCode = computed(() => {
  // Convert nested object structure to yaml
  const convertToYaml = (obj: any, indent = 0) => {
    let yaml = ''
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key]
        if (value === null) continue;
        if (typeof value === 'object') {
          yaml += `${' '.repeat(indent)}${key}:\n${convertToYaml(value, indent + 2)}`
        } else {
          yaml += `${' '.repeat(indent)}${key}: ${value}\n`
        }
      }
    }
    return yaml
  }
  return convertToYaml(props.properties)
})

</script>

<template>
  <div class="mb-3 text-start">
    <div>
      <div>

        <v-tabs v-model="propertiesType" color="primary" density="compact" :grow="true"
                align-tabs="center">
          <v-tab value="properties">Properties</v-tab>
          <v-tab value="yaml">Yaml</v-tab>
        </v-tabs>
        <template v-if="propertiesType === 'properties'">
          <v-row>
            <v-col cols="12">
              <CodeBlock :code="propertiesCode" :highlightjs="true"
                         :theme="codeTheme" lang="properties"
                         persistentCopyButton/>
            </v-col>
          </v-row>
        </template>
        <template v-if="propertiesType === 'yaml'">
          <v-row>
            <v-col cols="12">
              <CodeBlock :code="yamlCode" :highlightjs="true"
                         :theme="codeTheme" lang="yaml"
                         persistentCopyButton/>
            </v-col>
          </v-row>
        </template>
      </div>
    </div>
  </div>
</template>


<style scoped>
</style>
