<script setup lang="ts">

const shown = ref(false)

</script>
<template>
  <a @click.prevent="shown = true" class="text-primary cursor-pointer">
    <slot></slot>
  </a>
  <InstantDialog v-if="shown" title="Data Collection of AxonIQ Console" @closed="shown=false" show-close>
    <p>
      Your applications will start sending data to AxonIQ Console once you have connected them. We understand
      your concerns about what data will be shared. For this reason, the library is Open-Source and you can verify
      that we don't send more information than we tell you we are. You can
      <a href="https://github.com/AxonIQ/console-framework-client" target="_blank" class="text-primary">find the library
        on Github.</a>
    </p>
    <p class="mt-4">
      The data sent to AxonIQ Console is <b>only</b> the following information:
    </p>
    <div class="ml-2">
      <p class="h4 mt-2">Event Processor metrics</p>
      <p>Sent every 2 seconds to AxonIQ Console</p>
      <ul class="ml-6">
        <li>Processor Name</li>
        <li>Latency of messages</li>
        <li>Status of Segments (processing, error, unclaimed)</li>
        <li>DLQ Size</li>
        <li>Token Position</li>
      </ul>
      <p class="h4 mt-2">Handler Metrics</p>
      <p>Sent every 10 seconds to AxonIQ Console</p>
      <ul class="ml-6">
        <li>Names of Messages (e.g. MyAxonEvent)</li>
        <li>Class Names of Components (e.g. MyAxonEventHandler)</li>
        <li>Throughput, error rate and latency statistics</li>
        <li>Message correlation (Message A handled by X leads to Message B)</li>
      </ul>

      <p class="h4 mt-2">Dead Letter Information</p>
      <p>
        Only retrieved upon request by the user in AxonIQ Console. Level of returned information depends on the <code>dlq-mode</code>
        setting of the library. The <code>dlq-mode</code> is set to <code>off</code> by default.
      </p>
      <v-table density="compact">
        <thead>
        <tr>
          <th></th>
          <th class="text-center">none</th>
          <th class="text-center">masked</th>
          <th class="text-center">limited</th>
          <th class="text-center">full</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>Message Name</td>
          <td class="text-center">
            <XIcon class="text-medium-emphasis"/>
          </td>
          <td class="text-center">
            <CheckIcon class="text-success"/>
          </td>
          <td class="text-center">
            <CheckIcon class="text-success"/>
          </td>
          <td class="text-center">
            <CheckIcon class="text-success"/>
          </td>
        </tr>
        <tr>
          <td>Error Type</td>
          <td class="text-center">
            <XIcon class="text-medium-emphasis"/>
          </td>
          <td class="text-center">
            <CheckIcon class="text-success"/>
          </td>
          <td class="text-center">
            <CheckIcon class="text-success"/>
          </td>
          <td class="text-center">
            <CheckIcon class="text-success"/>
          </td>
        </tr>
        <tr>
          <td>Error Message</td>
          <td class="text-center">
            <XIcon class="text-medium-emphasis"/>
          </td>
          <td class="text-center">
            <XIcon class="text-medium-emphasis"/>
          </td>
          <td class="text-center">
            <XIcon class="text-medium-emphasis"/>
          </td>
          <td class="text-center">
            <CheckIcon class="text-success"/>
          </td>
        </tr>
        <tr>
          <td>MetaData</td>
          <td>
            <XIcon class="text-medium-emphasis"/>
          </td>
          <td class="text-center">
            <XIcon class="text-medium-emphasis"/>
          </td>
          <td class="text-center">
            <CheckIcon class="text-success"/>
          </td>
          <td class="text-center">
            <CheckIcon class="text-success"/>
          </td>
        </tr>
        <tr>
          <td>Aggregate IDs</td>
          <td>
            <XIcon class="text-medium-emphasis"/>
          </td>
          <td class="text-center">
            <XIcon class="text-medium-emphasis"/>
          </td>
          <td class="text-center">
            <CheckIcon class="text-success"/>
          </td>
          <td class="text-center">
            <CheckIcon class="text-success"/>
          </td>
        </tr>
        <tr>
          <td>Payload</td>
          <td>
            <XIcon class="text-medium-emphasis"/>
          </td>
          <td class="text-center">
            <XIcon class="text-medium-emphasis"/>
          </td>
          <td class="text-center">
            <XIcon class="text-medium-emphasis"/>
          </td>
          <td class="text-center">
            <CheckIcon class="text-success"/>
          </td>
        </tr>
        </tbody>
      </v-table>
    </div>

    <p class="h3 mt-4">Protective measures</p>
    <p>We have also taken measures to safeguard the data you send to us while using AxonIQ Console. Please check our
      <a href="https://www.axoniq.io/privacy-policy" target="_blank" class="text-primary">Privacy Policy</a> and <a
          href="https://lp.axoniq.io/axoniq-data-processing-addendum" target="_blank" class="text-primary">Data
        Processing Addendum</a> for the measures we implemented to protect your data.</p>
  </InstantDialog>
</template>
