<script setup lang="ts">
import {computed, onMounted, onUnmounted, ref} from "vue";
import CodeBlock from "vue3-code-block";
import {useVersionsStore} from "~/stores/versions";
import {storeToRefs} from "pinia";
import {useCodeTheme} from "~/utils/codeTheme";


const props = defineProps<{
  groupId: string,
  artifactId: string,
}>()
const {codeTheme} = useCodeTheme()

const localStorageKey = 'dependencySystem'
const eventKey = 'dependencySystemChanged'
const dependencySystem = ref('maven')

const dependencySystemEventListener = () => {
  dependencySystem.value = localStorage.getItem(localStorageKey) as string
}

const {versions} = storeToRefs(useVersionsStore())
const {fetchVersion} = useVersionsStore()

const libKey = computed(() => `${props.groupId}:${props.artifactId}`)
const actualVersion = ref('?')

onMounted(() => {
  if (!localStorage.getItem(localStorageKey)) {
    localStorage.setItem(localStorageKey, 'maven')
  }
  dependencySystem.value = localStorage.getItem(localStorageKey) as string

  watchEffect(() => {
    localStorage.setItem(localStorageKey, dependencySystem.value)
    console.log("setting " + dependencySystem.value)
    window.dispatchEvent(new Event(eventKey));
  })

  window.addEventListener(eventKey, dependencySystemEventListener)
})

watch([libKey], async () => {
  if (!versions[libKey.value]) {
    let value = await fetchVersion(libKey.value);
    actualVersion.value = value

  }
}, {immediate: true})


onUnmounted(() => {
  window.removeEventListener(eventKey, dependencySystemEventListener)
});

const gradleCode = computed(() => (`implementation group: '${props.groupId}', name: '${props.artifactId}', version: '${versions[libKey]?.version}'`))
const gradleShortCode = computed(() => (`implementation '${props.groupId}:${props.artifactId}:${actualVersion.value}'`))
const gradleKotlinCode = computed(() => (`implementation("${props.groupId}:${props.artifactId}:${actualVersion.value}")`))
const sbtCode = computed(() => (`libraryDependencies += "${props.groupId}" % "${props.artifactId}" % "${actualVersion.value}"`))
const mavenCode = computed(() => (`<dependency>
    <groupId>${props.groupId}</groupId>
    <artifactId>${props.artifactId}</artifactId>
    <version>${actualVersion.value}</version>
</dependency>`))

</script>

<template>
  <div class="mb-3 text-start">
    <div>
      <div>
        <v-alert v-if="!actualVersion" color="error" icon="mdi-alert" dense class="mb-4">
          <p class="mb-0">Could not fetch version information for <strong>{{libKey}}</strong>. Please check your maven provider for the latest version.</p>
        </v-alert>

        <v-tabs v-model="dependencySystem" color="primary"
                align-tabs="center" density="compact" :grow="true">
          <v-tab value="maven">Maven</v-tab>
          <v-tab value="gradle">Gradle</v-tab>
          <v-tab value="gradleShort">Gradle (Short)</v-tab>
          <v-tab value="gradleKotlin">Gradle (Kotlin)</v-tab>
          <v-tab value="sbt">SBT</v-tab>
        </v-tabs>
        <div v-if="dependencySystem === 'maven'">
          <CodeBlock :code="mavenCode" :highlightjs="true"
                     :theme="codeTheme" lang="xml"
                     persistentCopyButton/>
        </div>
        <div v-if="dependencySystem === 'gradle'">
          <CodeBlock :code="gradleCode" :highlightjs="true"
                     :theme="codeTheme" lang="gradle"
                     persistentCopyButton/>
        </div>
        <div v-if="dependencySystem === 'gradleShort'">
          <CodeBlock :code="gradleShortCode" :highlightjs="true"
                     :theme="codeTheme" lang="groovy"
                     persistentCopyButton/>
        </div>
        <div v-if="dependencySystem === 'gradleKotlin'">

          <CodeBlock :code="gradleKotlinCode" :highlightjs="true"
                     :theme="codeTheme" lang="groovy"
                     persistentCopyButton/>
        </div>
        <div v-if="dependencySystem === 'sbt'">
          <CodeBlock :code="sbtCode" :highlightjs="true"
                     :theme="codeTheme" lang="scala"
                     persistentCopyButton/>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
</style>
