<script setup lang="ts">
import CodeBlock from 'vue3-code-block';
import DependencySystemInstructions from "~/components/DependencySystemInstructions.vue";
import {getConfig} from "~/utils/configuration";
import {useCodeTheme} from "~/utils/codeTheme";
import {useNamespace} from "~/stores/namespaces";
import PropertiesCodeBlock from "~/components/PropertiesCodeBlock.vue";

import springBootImg from "assets/logo/spring-boot.png";
import dukeLogoImg from "assets/logo/duke.png";
import {storeToRefs} from "pinia";
import {useSpacesStore} from "~/stores/spaces";


const props = defineProps<{
  workspaceId: string,
  environmentId: string,
  accessToken?: string,
  showDemo?: boolean,
  onReady?: () => Promise<void>,
  skipWait?: boolean
}>()

const {codeTheme} = useCodeTheme()
const {workspaces} = storeToRefs(useSpacesStore())
const {namespace, loading, error} = useNamespace(props.workspaceId, props.environmentId)
const router = useRouter()

const actualAccessToken = ref(props.accessToken ? props.accessToken : namespace.value?.accessTokens[0]?.token)
const applicationName = ref('Awesome-Microservice')
const dlqMode = ref('NONE')
const dlqModeOptions = [
  {
    title: 'None - Only DLQ Size metrics',
    value: 'NONE',
  },
  {
    title: 'Masked - Masked identifiers, no payloads',
    value: 'MASKED',
  },
  {
    title: 'Limited - All data except payload',
    value: 'LIMITED',
  },
  {
    title: 'Full - All data',
    value: 'FULL',
  }
]

const flowOption = ref<null | string>(null)

const environmentConfig = getConfig()
const springProperties = computed(() => {
  const properties: any = {
    axoniq: {console: {}}
  }
  if (!namespace.value) {
    return properties
  }
  properties.axoniq.console = {
    'application-name': applicationName.value.length > 0 ? applicationName.value : null,
    credentials: `${namespace.value.identifier}:${actualAccessToken.value}`,
  }
  if (environmentConfig.connectorHost !== 'framework.console.axoniq.io') {
    properties.axoniq.console.host = environmentConfig.connectorHost
  }
  if (environmentConfig.connectorPort !== 7000) {
    properties.axoniq.console.port = environmentConfig.connectorPort
  }
  if (environmentConfig.connectorUnsecure) {
    properties.axoniq.console.secure = "false"
  }
  properties.axoniq.console['dlq-mode'] = dlqMode.value
  return properties
})


const javaConfiguration = computed(() => {
  if (!namespace.value) {
    return ``
  }
  let builder = "    AxoniqConsoleConfigurerModule\n" +
      `        .builder(\n` +
      `            "${namespace.value.identifier}",\n` +
      `            "${actualAccessToken.value}",\n` +
      `            "${applicationName.value.length > 0 ? applicationName.value : 'My Application Name'}"\n` +
      `        )\n` +
      `        .dlqMode(AxoniqConsoleDlqMode.${dlqMode.value})`
  if (environmentConfig.connectorHost !== 'framework.console.axoniq.io') {
    builder += `\n        .host("${environmentConfig.connectorHost}")`
  }
  if (environmentConfig.connectorPort !== 7000) {
    builder += `\n        .port(${environmentConfig.connectorPort})`
  }
  if (environmentConfig.connectorUnsecure) {
    builder += `\n        .secure(false)`
  }
  return "public void configure(Configurer configurer) {\n" + ''
      + `${builder}` + "\n"
      + `        .build()\n`
      + `        .configureModule(configurer);\n}`
})

const stepperStepModel = ref(1)
const chooseFlow = (flow: string) => {
  flowOption.value = flow
  stepperStepModel.value = 1
}

const finishLoading = ref(false)
const finish = () => {
  finishLoading.value = true
  if (props.onReady) {
    props.onReady()
        .then(() => {
          router.push(`/workspace/${props.workspaceId}/env/${props.environmentId}/start`)
        })
        .finally(() => {
          finishLoading.value = false
        })
  } else {
    router.push(`/workspace/${props.workspaceId}/env/${props.environmentId}/start`)
  }
}

const clusterId = computed(() => {
  return workspaces.value.find(s => s.identifier === props.workspaceId)?.environments?.find(e => e.identifier === props.environmentId)?.cluster?.clusterId
})

watch(namespace, (ns) => {
  if (ns && !actualAccessToken.value) {
    actualAccessToken.value = ns.accessTokens[0].token
  }
}, {immediate: true})
</script>
<template>
  <p class="mt-4 text-error" v-if="error">Something went wrong loading what we needed. Please contact AxonIQ to receive
    support.</p>
  <div v-else-if="loading" class="mt-4">
    <v-progress-linear indeterminate color="primary"/>

    <p class="mt-4">Loading what we need to show you the instructions...</p>
  </div>
  <div v-else-if="namespace">
    <div class="step-content-container mt-4" v-if="!flowOption">
      <slot name="intro">
        <p class="h3 py-2">
          Choose installation method
        </p>
      </slot>
      <v-row class="my-4">
        <v-col :cols="showDemo ? 4 : 6">
          <div elevation="0" class="w-100 pa-4 text-center h-100">
            <div class="d-flex flex-column justify-space-between h-100">
              <div class="d-flex align-center mb-2">
                <v-img :src="springBootImg" eager :width="100" :height="100"/>
              </div>
              <p></p>
              <v-btn class="w-100 mt-4" color="primary" @click="chooseFlow('springBoot')" elevation="4">
                Spring Boot Starter
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col :cols="showDemo ? 4 : 6">
          <div elevation="0" class="w-100 pa-4 text-center h-100">
            <div class="d-flex flex-column justify-space-between h-100">
              <div class="d-flex align-center mb-2">
                <v-img :src="dukeLogoImg" eager :width="100" height="100"/>
              </div>
              <v-btn class="w-100 mt-4" color="primary" @click="chooseFlow('plainJava')" elevation="4">
                Java Configuration
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col v-if="showDemo">
          <v-card elevation="0" class="w-100 pa-4 text-center h-100">
            <div class="d-flex flex-column justify-space-between h-100">
              <div class="mb-2 text-center">
                <BikeIcon :width="100" height="100" class="text-primary"/>
              </div>
              <v-btn class="w-100 mt-4" color="primary"
                     :to="`/demo-setup?workspaceId=${workspaceId}&environmentId=${environmentId}&clusterId=${clusterId}`"
                     elevation="4">
                Run a demo
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div v-if="flowOption === 'springBoot' || flowOption === 'plainJava'">
      <!-- Step 1: Dependency -->
      <template v-if="stepperStepModel=== 1">
        <div class="step-content-container">
          <p class="h3 py-2">Add the Console Dependency</p>
          <template v-if="flowOption === 'springBoot'">
            <p class="mb-2">
              Add our Spring Boot starter to your project. Make sure you use Axon Framework 4.6.0 or
              higher to be able to connect with AxonIQ Console.</p>
            <DependencySystemInstructions group-id="io.axoniq.console"
                                          artifact-id="console-framework-client-spring-boot-starter"
            />
          </template>
          <template v-else-if="flowOption === 'plainJava'">
            <p class="mb-2">Add our dependency to your project. Make sure you use Axon Framework 4.6.0 or
              higher to be able to connect with AxonIQ Console.</p>

            <DependencySystemInstructions group-id="io.axoniq.console" artifact-id="console-framework-client"/>
          </template>
          <p class="text-medium-emphasis">
            Want to know which data will be collected after adding this dependency?
            <DataExplanationDialog>Learn more about it here.</DataExplanationDialog>
          </p>
        </div>
      </template>

      <!-- Step 3: Configuration -->
      <template v-if="stepperStepModel=== 2">
        <div class="step-content-container">
          <p class="h3 py-2">Configure AxonIQ Console</p>
          <p>
            Let's configure AxonIQ Console! Specify a name for the application your are about to connect. All
            metrics are unique to an application.
          </p>

          <v-text-field v-model="applicationName" label="Application Name" class="mt-4" density="compact"/>
          <template v-if="namespace.accessTokens.length > 1 && !props.accessToken">
            <p class="mt-4">
              We found more than one access token in your environment. Please select the one you want to
              use.
            </p>

            <v-select v-model="actualAccessToken"
                      :items="namespace.accessTokens.map(t => ({title: t.name, value: t.token}))"
                      class="mt-2"/>
          </template>

          <p>What DLQ mode do you want your application to use?
            <DataExplanationDialog>You can find out more about this here.</DataExplanationDialog>
            DLQ Access is off by default.
          </p>

          <v-select v-model="dlqMode" :items="dlqModeOptions" class="mt-2"/>
          <template v-if="flowOption === 'springBoot'">
            <p class="mb-2">Based on the input you have given, please add the following properties to your application
              to configure it:</p>
            <PropertiesCodeBlock :properties="springProperties"/>
          </template>
          <template v-else-if="flowOption === 'plainJava'">
            <template v-if="namespace.accessTokens.length > 1 && !props.accessToken">
              <p>You should specify a name for the application. All metrics are unique to an
                application. If you don't specify a name, we will fill in a default value for you.</p>
              <v-text-field v-model="applicationName" label="Application Name" class="mt-6"/>
              <p class="mt-4">
                We found more than one access token in your environment. Please select the one you want to
                use.
              </p>

              <v-select v-model="actualAccessToken"
                        :items="namespace.accessTokens.map(t => ({title: t.name, value: t.token}))"
                        class="mt-2"/>
            </template>
            <p class="mb-2">
              Based on the input you have given, please use the <code>ConfigurerModule</code> on the right to instruct
              your Axon Framework configuration
              on how to use our components.
            </p>
            <CodeBlock :code="javaConfiguration" :highlightjs="true" class="text-start"
                       :theme="codeTheme" lang="java" persistentCopyButton/>
          </template>
        </div>
      </template>

      <!-- Finish! -->
      <template v-if="stepperStepModel=== 3">
        <p class="h3 py-2">Ready!</p>
        <div class="step-content-container">
          <p>
            You are now ready to start your application with the power of AxonIQ Console. Your applications
            will start reporting statistics and processor status that we turn into valuable insights for you and
            your team.
          </p>

          <template v-if="!skipWait">
            <div class="mt-8">
              <div v-if="namespace?.applications?.length < 1">
                <v-progress-linear indeterminate height="25" color="secondary"
                                   rounded>
                  Waiting for your application to start sending data...
                </v-progress-linear>
                <p class="mt-2">
                  Once we receive
                  <DataExplanationDialog>data from your application</DataExplanationDialog>
                  , we will redirect you to your project's Workspace so
                  you can explore. <a href="#" @click="finish" class="text-primary">Click here to skip this and go to
                  the Workspace.</a>
                </p>

              </div>
              <template v-else>
                <p>Congratulations! We are receiving data from the following application(s):</p>
                <ul class="ml-8 mt-2">
                  <li v-for="application of namespace.applications">
                    {{ application.name }}
                  </li>
                </ul>
              </template>
            </div>
          </template>
        </div>
      </template>


      <div class="d-flex justify-space-between pa-4">
        <div>
          <v-btn elevation="4" @click="() => {
              if(stepperStepModel === 1) {
                flowOption = null
              } else {
                stepperStepModel -= 1
              }
            }">
            <ArrowLeftIcon class="mr-2"/>
            Back
          </v-btn>
        </div>
        <div>
          <v-btn color="secondary" elevation="4" @click="stepperStepModel += 1"
                 v-if="stepperStepModel < 3">
            Next Step
            <ArrowRightIcon class="ml-2"/>
          </v-btn>
          <v-btn v-if="stepperStepModel === 3 && namespace.applications.length > 0" color="secondary" elevation="4"
                 @click="finish()" :loading="finishLoading">
            <PlayerTrackNextIcon class="mr-2"/>
            Go to my workspace!
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.step-content-container {
  min-height: 320px;
}
</style>